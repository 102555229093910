import Axios from 'axios'
import _ from 'lodash'

let pollInterval = 10000
let pollTimeout = 6 * 60 * 1000

export async function waitForEnvironment(envObj) {
    let result = false

    if (envObj && envObj.waitFor && envObj.waitFor.length) {
        let waitForList = envObj.waitFor

        for (let waitFor of waitForList) {
            result = await poll(
                () => {
                    return Axios(waitFor.url, {
                        method: waitFor.params ? 'POST' : 'GET',
                        params: waitFor.params,
                        timeout : 5000,
                        headers: {
                            'Accept': 'application/json',
                            'Access-Control-Allow-Origin': true

                        }
                    }).then(function (response) {
                        return response.data
                    })
                },
                pollTimeout,
                pollInterval,
                waitFor
            )
        }
    }

    return result
}

async function poll(fn, timeout, interval, waitData) {
    const endTime = Date.now() + (timeout || 20000)

    let response = null

    try {
        response = await fn()
    } catch (e) {
        console.warn(e, e.stack)
    }

    while ((!response || getValue(response, waitData) !== waitData.success) && Date.now() < endTime) {
        console.log(response, getValue(response, waitData), waitData.success)
        try {
            response = await fn()
        } catch (e) {
            console.warn(e, e.stack)
        }

        await delay(interval)
    }

    if (response && getValue(response, waitData) === waitData.success) {
        return response
    } else {
        throw new Error("Timeout waiting for service")
    }

    function getValue(response, waitData) {
        if (waitData.path) {
            return _.get(response, waitData.path)
        } else {
            return response
        }
    }
}

function delay(time) {
    return new Promise(function (accept) {
        setTimeout(accept, time)
    })
}
