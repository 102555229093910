import React, { Component } from 'react'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import CircularProgress from '@material-ui/core/CircularProgress'
import config from '../config'
import { waitForEnvironment } from '../waitUtils'


export default class Wait extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isHealthy: [],
            loading: false,
            servicesCount:null,
            waitMessage: "The environment is starting ..."
        }
        this.startWaitAndRedirect = this.startWaitAndRedirect.bind(this);
    }

    componentDidMount(){
	    this.startWaitAndRedirect();
	 }

	async startWaitAndRedirect(){
		this.setState({ loading: true });
    	let params = this.props.match.params
    	let configEnv = config.environments
        let envObj = configEnv[params.environmentId]
        let result = false
        const { history } = this.props

        let redirectRoute = "/Schemes/" + params.environmentId

        try {
            result = await waitForEnvironment(envObj)
        } catch (e) {
            console.warn(e, e.stack)
            return this.setState({
                error: true,
                loading:false,
                success: false,
                errorMessage: e.message
            })
        }

        return setTimeout(() => history.push(redirectRoute), 5000)
	}

    render() {
    	const { waitMessage } = this.state.waitMessage
        return (
    	    <div className="Wait-page" >
		    	<Typography component="h1" variant="h5" > { this.state.waitMessage }</Typography>
		      	<CircularProgress size={20} />
		    </div>
        )
    }
}
